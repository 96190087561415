import React from "react";
import styled from "styled-components";
import CheckIcon from "../../svg/images/check.svg";
import { bytesToSize } from "../../utils";
import PropTypes from "prop-types";
import humanizeDuration from "humanize-duration";
import theme from "src/components/theme";

const PlanCardContainer = styled.div`
  border-radius: 10px;
  background-color: ${theme.cardsBackground};
  border: solid 1px ${theme.backgroundColor};
  margin: 0 16px 20px 16px;
  padding-bottom: 16px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    width: 360px;
  }
`;

const PlanTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 2px;
  color: ${theme.titleColor};
  border-radius: 10px 10px 0 0;
  border-bottom: 3px solid ${theme.accentColor};
  background-color: #191920;
`;

const PriceTopDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  font-size: 18px;
  line-height: 1.33;
  color: ${theme.bodyColor};
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 4px 0 0 20px;
`;

const CurrencySignText = styled.span`
  font-size: 20px;
  color: ${theme.titleColor};
`;

const PriceText = styled(CurrencySignText)`
  padding-bottom: 0;
  width: auto;
  font-size: 64px;
  font-weight: 300;
  letter-spacing: -1.8px;
  margin-left: 3px;
`;

const PeriodText = styled(CurrencySignText)`
  font-size: 18px;
  line-height: 1.06;
`;

const DescriptionText = styled.div`
  height: 70px;
  font-size: 18px;
  line-height: 1.33;
  color: ${theme.titleColor};
  text-align: center;
  display: block;
  margin: 30px 30px 0 30px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    margin: 30px 40px 0 40px;
    height: 120px;
  }
`;

const FeaturesContainer = styled.div`
  margin: 0 30px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    margin: 0 40px;
  }
`;

const StyledFeatureRow = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: flex-start;
`;

const FeatureText = styled.span`
  display: inline-block;
  margin-left: 16px;
  font-size: 16px;
  line-height: 1.88;
  letter-spacing: -0.2px;
  color: ${theme.titleColor};

  b {
    color: ${theme.accentColor};
  }
`;

const StyledCheckIcon = styled.svg.attrs({
  viewBox: CheckIcon.viewBox,
})`
  width: 25px;
  min-width: 25px;
`;

const FeatureRow = ({ children }) => {
  return (
    <StyledFeatureRow>
      <StyledCheckIcon>
        <use xlinkHref={`#${CheckIcon.id}`} />
      </StyledCheckIcon>
      <FeatureText>{children}</FeatureText>
    </StyledFeatureRow>
  );
};

FeatureRow.propTypes = {
  children: PropTypes.node,
};

export const PlanCard = ({ plan }) => {
  const topDescription = plan.type === "free" ? "Single user" : "Starting from";
  let price = (plan.price / 100).toFixed(2);
  return (
    <PlanCardContainer>
      <PlanTitle>{plan.name}</PlanTitle>
      <PriceTopDescription>{topDescription}</PriceTopDescription>
      <PriceRow>
        <CurrencySignText>$</CurrencySignText>
        <PriceText>{price}</PriceText>
        <PeriodText>/mo</PeriodText>
      </PriceRow>
      <DescriptionText>{plan.description}</DescriptionText>
      <FeaturesContainer>
        <FeatureRow>
          <b>{bytesToSize(plan.trafficPrepaid)}</b> of traffic
        </FeatureRow>
        {plan.accountType === "organization" && (
          <FeatureRow>
            <b>{plan.seats}</b> {plan.seats === 1 ? "user" : "team members"}
          </FeatureRow>
        )}
        <FeatureRow>
          <b>{humanizeDuration(plan.sessionRetentionTime * 1000, { largest: 1 })}</b> of sessions history
        </FeatureRow>
      </FeaturesContainer>
    </PlanCardContainer>
  );
};

export const PlanShape = PropTypes.shape({
  accountType: PropTypes.oneOf(["user", "organization"]),
  period: PropTypes.oneOf(["monthly", "annually", "lifetime", "trial"]),
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  seats: PropTypes.number.isRequired,
  sessionRetentionTime: PropTypes.number.isRequired,
  timePrepaid: PropTypes.number.isRequired,
  trafficPrepaid: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["trial", "free", "full"]),
});

PlanCard.propTypes = {
  plan: PlanShape,
};
