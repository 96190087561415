import React from "react";
import styled from "styled-components";
import Section, { SectionContent } from "../sections/Section";
import { graphql, useStaticQuery } from "gatsby";
import { DuckImage, H2 } from "../Common";
import * as PricingStyles from "./PricingStyles";
import theme from "src/components/theme";

const SectionContainer = styled(Section)`
  background-color: ${theme.lightBackgroundColor};

  ${SectionContent} {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    justify-content: flex-start;
    align-items: center;

    @media (min-width: ${theme.mobileBreakpoint}) {
      padding: 40px;
      flex-direction: row-reverse;
      justify-content: center;
    }
  }
`;

const Title = styled(H2)`
  text-align: center;
  margin-top: 30px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    text-align: left;
    margin-top: 0;
  }
`;

const Description = styled(PricingStyles.Description)`
  max-width: 474px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    font-size: 24px;
    line-height: 1.29;
    text-align: left;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${theme.mobileBreakpoint}) {
    align-items: flex-start;
  }
`;

const Button = styled(PricingStyles.LinkButton)`
  @media (min-width: ${theme.mobileBreakpoint}) {
    max-width: 300px;
    margin-top: 40px;
  }
`;

export const NeedMoreSection = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "bananaduck.png" }) {
        childImageSharp {
          fluid(maxWidth: 329) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <SectionContainer>
      <DuckImage fluid={data.file.childImageSharp.fluid} />
      <DetailsContainer>
        <Title>Need More?</Title>
        <Description>Can’t find any suitable plan? Contact us and we’ll think how to customize it for you.</Description>
        <Button href="mailto:info@appspector.com">Contact Us</Button>
      </DetailsContainer>
    </SectionContainer>
  );
};
