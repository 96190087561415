import React from "react";
import styled from "styled-components";
import Section, { SectionContent } from "../sections/Section";
import { H2 } from "../Common";
import * as PricingStyles from "./PricingStyles";
import theme from "src/components/theme";

const ContactSectionContainer = styled(Section)`
  background-color: ${theme.backgroundColor};

  ${SectionContent} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const TrialText = styled(H2)`
  text-align: center;
  margin-top: 30px;
  font-weight: normal;

  @media (min-width: ${theme.mobileBreakpoint}) {
    margin-top: 60px;
  }

  b {
    letter-spacing: 1.4px;
  }
`;

const ContactSection = () => (
  <ContactSectionContainer>
    <TrialText>
      Start your free <b>14-day trial</b> now!
    </TrialText>
    <PricingStyles.LinkButton href="/signup">Start Now</PricingStyles.LinkButton>
    <PricingStyles.Description>no credit card required</PricingStyles.Description>
  </ContactSectionContainer>
);

export default ContactSection;
