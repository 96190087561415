import React from "react";
import styled from "styled-components";
import { bytesToSize } from "../../utils";
import PropTypes from "prop-types";
import { PlanShape } from "./PlanCard";
import theme from "src/components/theme";

const PlanCardContainer = styled.div`
  border-radius: 4px;
  background-color: ${theme.cardDarkLineBackground};
  margin-top: 16px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    border-radius: unset;
    padding: 0 4px;

    &:nth-child(odd) {
      background-color: ${theme.cardLightLineBackground};
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }

  &:first-child {
    margin-top: 0;
  }
`;

const PlanTitle = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 55px;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1.5px;
  color: ${theme.titleColor};
  border-radius: 4px 4px 0 0;
  border-bottom: 3px solid ${theme.accentColor};
  padding-left: 16px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    height: 70px;
    border-bottom: unset;
    border-radius: unset;
    font-size: 24px;
    letter-spacing: 2px;
    padding-right: 16px;
    flex: 0 1 230px;
    min-width: 166px;
  }
`;

const FeatureRow = styled.div`
  flex: 1;
  display: flex;
  padding: 0 16px;
  height: 42px;
  justify-content: space-between;
  align-items: center;

  &:nth-child(odd) {
    background-color: ${theme.cardLightLineBackground};

    @media (min-width: ${theme.mobileBreakpoint}) {
      background-color: unset;
    }
  }

  div:first-child {
    font-size: 12px;
    line-height: 0.83;
    letter-spacing: -0.2px;
    text-transform: uppercase;

    @media (min-width: ${theme.mobileBreakpoint}) {
      display: none;
    }
  }

  div:last-child {
    font-size: 14px;
    line-height: 2.14;
    letter-spacing: -0.2px;
    color: ${theme.titleColor};

    @media (min-width: ${theme.mobileBreakpoint}) {
      font-size: 18px;
      line-height: 1.67;
    }
  }

  @media (min-width: ${theme.mobileBreakpoint}) {
    height: 70px;
    padding-left: 0;

    &:nth-child(2) {
      flex: 0 1 210px;
      min-width: 84px;
    }

    &:nth-child(3) {
      flex: 0 1 190px;
      min-width: 37px;
    }

    &:last-child {
      flex: 1 1 285px;
      min-width: 210px;
    }
  }
`;

export const PlanItem = ({ plan }) => {
  const price = (plan.price / 100).toString();
  const annualPrice = (plan.annualPrice / 100).toString();

  return (
    <PlanCardContainer>
      <PlanTitle>{plan.name}</PlanTitle>
      <FeatureRow>
        <div>Traffic</div>
        <div>{bytesToSize(plan.trafficPrepaid)}</div>
      </FeatureRow>
      <FeatureRow>
        <div>Seats</div>
        <div>{plan.seats}</div>
      </FeatureRow>
      <FeatureRow>
        <div>Price</div>
        <div>
          ${price} per month / ${annualPrice} per year
        </div>
      </FeatureRow>
    </PlanCardContainer>
  );
};

PlanItem.propTypes = {
  plan: {
    ...PlanShape.propTypes,
    annualPrice: PropTypes.number.isRequired,
  },
};
