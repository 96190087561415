import React from "react";
import styled from "styled-components";
import Section, { SectionContent } from "../sections/Section";
import { H2 } from "../Common";
import PropTypes from "prop-types";
import { PlanShape, PlanCard } from "./PlanCard";
import { PlanItem } from "./PlanItem";
import theme from "src/components/theme";

const PlansSectionContainer = styled(Section)`
  background-color: ${theme.backgroundColor};

  ${SectionContent} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  b {
    color: ${theme.accentColor};
  }
`;

const HeaderPlansSectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @media (min-width: ${theme.mobileBreakpoint}) {
    margin-top: 60px;
  }
`;

const Title = styled(H2)`
  text-align: center;
`;

const SectionTitle = styled(H2)`
  text-align: center;
  font-size: 22px;
  margin-top: 30px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    display: none;
  }
`;

const PlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  margin-top: 20px;
  max-width: 960px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    margin-top: 60px;
  }
`;

const DesktopHeaderContainer = styled.div`
  display: none;
  height: 60px;
  justify-content: stretch;
  flex: auto;
  border-radius: 4px 4px 0 0;
  background-color: ${theme.cardLightLineBackground};
  border-bottom: 3px solid ${theme.accentColor};
  text-transform: uppercase;
  padding: 0 4px;
  align-items: center;
  font-size: 16px;
  letter-spacing: -0.2px;

  div {
    flex: 1;
    padding-right: 16px;

    &:nth-child(1) {
      flex: 0 1 230px;
      min-width: 166px;
      padding-left: 16px;
    }

    &:nth-child(2) {
      flex: 0 1 210px;
      min-width: 84px;
    }

    &:nth-child(3) {
      flex: 0 1 190px;
      min-width: 37px;
    }

    &:nth-child(4) {
      flex: 1 1 285px;
      min-width: 210px;
    }
  }

  @media (min-width: ${theme.mobileBreakpoint}) {
    display: flex;
  }
`;

const PlansDesktopHeader = props => (
  <DesktopHeaderContainer>
    <div>{props.children}</div>
    <div>Traffic</div>
    <div>Seats</div>
    <div>Price</div>
  </DesktopHeaderContainer>
);

PlansDesktopHeader.propTypes = {
  children: PropTypes.node,
};

export class PlansSection extends React.PureComponent {
  static propTypes = {
    plans: PropTypes.arrayOf(PlanShape),
  };

  render() {
    const individualPlans = this.getPlansArray(plan => plan.accountType === "user");

    let teamPlans = this.getPlansArray(plan => plan.accountType === "organization");

    return (
      <PlansSectionContainer>
        <Title>All availiable plans</Title>
        <SectionTitle>Personal Plans</SectionTitle>
        <PlansContainer>
          <PlansDesktopHeader>Personal Plans</PlansDesktopHeader>
          {individualPlans}
        </PlansContainer>
        <SectionTitle>Team Plans</SectionTitle>
        <PlansContainer>
          <PlansDesktopHeader>Team Plans</PlansDesktopHeader>
          {teamPlans}
        </PlansContainer>
      </PlansSectionContainer>
    );
  }

  getPlansArray = predicate => {
    return this.props.plans
      .filter(plan => predicate(plan))
      .map(plan => {
        return <PlanItem key={plan.id} plan={plan} />;
      });
  };

  handleIndividualPeriodChange = period => {
    this.setState({ individualPeriod: period });
  };

  handleTeamPeriodChange = period => {
    this.setState({ teamPeriod: period });
  };
}

export const HeaderPlansSection = props => {
  const cards = props.plans.map(plan => <PlanCard key={plan.id} plan={plan} />);
  return <HeaderPlansSectionContainer>{cards}</HeaderPlansSectionContainer>;
};

HeaderPlansSection.propTypes = {
  plans: PropTypes.arrayOf(PlanShape),
};
