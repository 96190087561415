import React from "react";
import styled from "styled-components";
import Section, { SectionContent } from "./sections/Section";
import { H2, H3, Text } from "./Common";
import PropTypes from "prop-types";
import theme from "src/components/theme";

const FAQSectionContainer = styled(Section)`
  background-color: ${theme.lightBackgroundColor};

  ${SectionContent} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const SectionTitle = styled(H2)`
  text-align: center;
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 880px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    margin-top: 20px;
  }
`;

const Question = styled.div`
  max-width: 100%;
  margin-top: 30px;

  @media (min-width: 1000px) {
    max-width: 45%;
  }

  @media (min-width: ${theme.mobileBreakpoint}) {
    margin-top: 40px;
  }
`;

const QuestionText = styled(H3)`
  letter-spacing: normal;
  line-height: normal;
  font-size: 22px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    line-height: 1.25;
  }
`;

const AnswerText = styled(Text)`
  display: block;
  margin-top: 10px;
  font-size: 18px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    margin-top: 7px;
  }
`;

const PlansSection = ({ id, questions }) => {
  return (
    <FAQSectionContainer id={id}>
      <SectionTitle>Frequently asked questions</SectionTitle>
      <QuestionsContainer>
        {questions.map((question, index) => (
          <Question key={index}>
            <QuestionText>{question.question}</QuestionText>
            <AnswerText>{question.answer}</AnswerText>
          </Question>
        ))}
      </QuestionsContainer>
    </FAQSectionContainer>
  );
};

PlansSection.propTypes = {
  id: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    }),
  ),
};

export default PlansSection;
