import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import TrialSection from "../components/pricing/TrialSection";
import { PlansSection, HeaderPlansSection } from "../components/pricing/PlansSection";
import FAQSection from "../components/FAQSection";
import ContactSection from "../components/sections/ContactSection";
import { graphql, useStaticQuery } from "gatsby";
import ReactHelmet from "react-helmet";
import { HeaderSectionContainer, HeaderTitle, HeaderTitleContainer } from "../components/Header";
import { NeedMoreSection } from "../components/pricing/NeedMoreSection.js";
import zip from "lodash/zip";
import Header from "src/elements/Header";
import Footer from "src/elements/Footer";

const PricingTitleContainer = styled(HeaderTitleContainer)`
  margin-top: -80px;
  transform: translateY(80px);
`;

const HeaderWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const PricingPage = () => {
  const data = useStaticQuery(graphql`
    {
      pricingData: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "//components/pricing.md$/" } }) {
        edges {
          node {
            frontmatter {
              title
              meta {
                description
              }
              questions {
                question
                answer
              }
            }
          }
        }
      }
      allBillingPlan {
        edges {
          node {
            plan {
              id
              name
              description
              type
              accountType
              price
              seats
              sessionRetentionTime
              trafficPrepaid
              timePrepaid
              period
            }
          }
        }
      }
    }
  `);

  const pricingFrontmatter = data.pricingData.edges[0].node.frontmatter;
  const billingPlans = data.allBillingPlan.edges
    .map(edge => edge.node.plan)
    .filter(plan => plan.type === "full")
    .sort((planA, planB) => planA.id.localeCompare(planB.id));
  const monthPlans = billingPlans.filter(plan => plan.period === "monthly");
  const annualPlans = billingPlans.filter(plan => plan.period === "annually");

  if (monthPlans.length !== annualPlans.length) {
    throw new Error("shit've just hit fan");
  }

  const plans = zip(monthPlans, annualPlans)
    .map(pair => {
      const plan = pair[0];
      plan.annualPrice = pair[1].price;
      return plan;
    })
    .sort((a, b) => a.price - b.price);

  const freePlan = {
    ...data.allBillingPlan.edges.find(edge => edge.node.plan.type === "free" && edge.node.plan.accountType === "user")
      .node.plan,
  };
  freePlan.name = "Free";

  const minPersonalPlan = {
    ...data.allBillingPlan.edges
      .filter(edge => edge.node.plan.type === "full" && edge.node.plan.accountType === "user")
      .sort((a, b) => a.node.plan.price - b.node.plan.price)[0].node.plan,
  };
  minPersonalPlan.name = "Personal";

  const minOrganizationPlan = {
    ...data.allBillingPlan.edges
      .filter(edge => edge.node.plan.type === "full" && edge.node.plan.accountType === "organization")
      .sort((a, b) => a.node.plan.price - b.node.plan.price)[0].node.plan,
  };
  minOrganizationPlan.name = "Team";

  const headerPlans = [freePlan, minPersonalPlan, minOrganizationPlan];

  return (
    <Layout header={null} footer={<Footer />}>
      <ReactHelmet
        title="Pricing"
        meta={[
          {
            name: "description",
            content: pricingFrontmatter.meta.description,
          },
        ]}
      />
      <HeaderSectionContainer>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <PricingTitleContainer>
          <HeaderTitle as="h1">{pricingFrontmatter.title}</HeaderTitle>
          <HeaderPlansSection plans={headerPlans} />
        </PricingTitleContainer>
      </HeaderSectionContainer>
      <TrialSection />
      <NeedMoreSection />
      <PlansSection plans={plans} />
      <FAQSection questions={pricingFrontmatter.questions} />
      <ContactSection />
    </Layout>
  );
};

export default PricingPage;
