import { AccentLinkButton } from "../Common";
import styled from "styled-components";
import theme from "src/components/theme";

export const LinkButton = styled(AccentLinkButton)`
  margin-top: 30px;
  width: 100%;
  height: 52px;
  font-size: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  @media (min-width: ${theme.mobileBreakpoint}) {
    max-width: 360px;
    height: 64px;
    line-height: 1.25;
    font-size: 24px;
  }
`;

export const Description = styled.div`
  font-size: 18px;
  line-height: 1.44;
  margin-top: 10px;
  text-align: center;
`;
